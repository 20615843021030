var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"text-h5 red--text"},[_c('span',{staticClass:"font-bold"},[_vm._v("Nota:")]),_vm._v(" Los datos ingresados en este paso son de la empresa a quien se facturará ")])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"vid":"countryId","name":"País de ubicación de la empresa a quien se factura","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.countries,"item-text":"name","item-value":"id","outlined":"","dense":"","label":"País de ubicación de la empresa a quien se factura*"},on:{"change":_vm.onChangeCompanyCountry},model:{value:(_vm.model.countryId),callback:function ($$v) {_vm.$set(_vm.model, "countryId", $$v)},expression:"model.countryId"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"vid":"companyName","name":"Nombre de la empresa a quien se factura","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Nombre de la empresa a quien se factura*","required":""},model:{value:(_vm.model.companyName),callback:function ($$v) {_vm.$set(_vm.model, "companyName", $$v)},expression:"model.companyName"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"vid":"companyIdentification","name":"Número NIT para la factura","rules":"required|max:80"},on:{"keypress":function($event){return _vm.isLetterOrNumber($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Número NIT para la factura*","required":""},model:{value:(_vm.model.companyIdentification),callback:function ($$v) {_vm.$set(_vm.model, "companyIdentification", $$v)},expression:"model.companyIdentification"}})]}}])})],1)])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('file-chooser',{ref:"fileChooser",attrs:{"options":{
              height: '220px',
              description: 'Haz clic aquí para cargar el ' + _vm.rutLabel + ' de la empresa. El archivo no debe ser superior a 30 días de expedido.',
              regex: /\.(jpg|jpeg|png|gif|pdf)$/,
              error: 'Solo se permiten imagenes o archivos PDF',
              fileSize: 2,
            }},on:{"input":_vm.onLoadRut}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":_vm.onDeleteImage}},'v-icon',attrs,false),on),[_vm._v(" mdi-trash-can-outline ")])]}}])},[_c('span',[_vm._v("Eliminar Imagen")])]),(_vm.roleId !== 3 && _vm.roleId !== 5 && _vm.model.rutFileUrl)?_c('a',{attrs:{"href":_vm.fileDirectory + _vm.model.rutFileUrl,"target":"_blank"}},[_vm._v("Descargar")]):_vm._e(),_c('span',{staticClass:"red--text"},[_vm._v(" El archivo no debe ser superior a 30 días de expedido.")])],1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mt-0 pt-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 mt-0 pt-0"},[_c('validation-provider',{attrs:{"vid":"currencyName","name":"Moneda de facturación"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Moneda de facturación*","required":"","disabled":""},model:{value:(_vm.currencyName),callback:function ($$v) {_vm.currencyName=$$v},expression:"currencyName"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6 mt-0 pt-0"},[_c('validation-provider',{attrs:{"vid":"paymentMethodName","rules":"required","name":"Forma de pago"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.paymentMethods,"item-text":"name","item-value":"id","outlined":"","dense":"","label":"Forma de pago*"},model:{value:(_vm.model.paymentMethodId),callback:function ($$v) {_vm.$set(_vm.model, "paymentMethodId", $$v)},expression:"model.paymentMethodId"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"vid":"companyContactName","name":"Contacto para envío de factura","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Contacto para envío de factura*","required":""},model:{value:(_vm.model.companyContactName),callback:function ($$v) {_vm.$set(_vm.model, "companyContactName", $$v)},expression:"model.companyContactName"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"vid":"countryId","name":"País","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.countries,"item-text":"name","item-value":"id","outlined":"","dense":"","label":"País*"},on:{"change":_vm.onChangeCountry},model:{value:(_vm.model.shippingCountryId),callback:function ($$v) {_vm.$set(_vm.model, "shippingCountryId", $$v)},expression:"model.shippingCountryId"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"vid":"companyContactPositionDetail","name":"Cargo del contacto","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Cargo del contacto*","required":""},model:{value:(_vm.model.companyContactPositionDetail),callback:function ($$v) {_vm.$set(_vm.model, "companyContactPositionDetail", $$v)},expression:"model.companyContactPositionDetail"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"vid":"countryId","name":"Departamento/estado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.departments,"item-text":"name","item-value":"id","outlined":"","dense":"","label":"Departamento/estado*"},on:{"change":_vm.onChangeDepartment},model:{value:(_vm.model.shippingDepartmentId),callback:function ($$v) {_vm.$set(_vm.model, "shippingDepartmentId", $$v)},expression:"model.shippingDepartmentId"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"vid":"companyContactEmail","name":"Correo autorizado para envío de factura electrónica","rules":"required|max:255|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","outlined":"","dense":"","error-messages":errors,"required":"","background-color":"yellow"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('strong',[_vm._v("Correo autorizado para envío de factura electrónica*")])]},proxy:true}],null,true),model:{value:(_vm.model.companyContactEmail),callback:function ($$v) {_vm.$set(_vm.model, "companyContactEmail", $$v)},expression:"model.companyContactEmail"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"vid":"shippingCityId","name":"Ciudad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.cities,"item-text":"name","item-value":"id","outlined":"","dense":"","label":"Ciudad*"},model:{value:(_vm.model.shippingCityId),callback:function ($$v) {_vm.$set(_vm.model, "shippingCityId", $$v)},expression:"model.shippingCityId"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"vid":"shippingAddress","name":"Dirección de entrega de factura","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","outlined":"","dense":"","error-messages":errors,"label":"Dirección de entrega de factura*","required":""},model:{value:(_vm.model.shippingAddress),callback:function ($$v) {_vm.$set(_vm.model, "shippingAddress", $$v)},expression:"model.shippingAddress"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"vid":"dayTermToSendInvoice","name":"Día del mes límite para entregar la factura","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Día del mes límite para entregar la factura*","required":""},model:{value:(_vm.model.dayTermToSendInvoice),callback:function ($$v) {_vm.$set(_vm.model, "dayTermToSendInvoice", $$v)},expression:"model.dayTermToSendInvoice"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"flex-grow-1"},[_c('validation-provider',{attrs:{"vid":"companyContactPhone","name":"Teléfono","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('vue-tel-input-vuetify',{staticClass:"flex-wrap",attrs:{"label":"Teléfono","mode":"international","error-messages":errors,"required":"","outlined":"","dense":"","preferred-countries":['CO', 'US']},model:{value:(_vm.model.companyContactPhone),callback:function ($$v) {_vm.$set(_vm.model, "companyContactPhone", $$v)},expression:"model.companyContactPhone"}})]}}])})],1),_c('div',{staticClass:"flex-grow-1"},[_c('validation-provider',{attrs:{"vid":"companyContactPhoneExtension","name":"Extensión","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Extensión"},model:{value:(_vm.model.companyContactPhoneExtension),callback:function ($$v) {_vm.$set(_vm.model, "companyContactPhoneExtension", $$v)},expression:"model.companyContactPhoneExtension"}})]}}])})],1)])]),_c('div',{staticClass:"col-12 text-h5 red--text"},[_c('h3',[_vm._v("Observaciones:")]),_c('span',[_vm._v(" Por favor cuéntanos qué necesitamos saber o recibir antes de que enviemos la factura o al momento del envío de la factura; ej: orden de compra, orden de pago, No. De entrada, etc. ")]),_c('h3',[_vm._v("Respuesta con cantidad máxima de 200 caracteres")])]),_c('div',{staticClass:"col-12 col-md-"},[_c('validation-provider',{attrs:{"vid":"description","name":"Descripción","rules":"required|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-textarea',{attrs:{"counter":"200","outlined":"","clearable":"","type":"text","dense":"","error-messages":errors,"clear-icon":"mdi-close-circle","label":"Descripción","required":""},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}})]}}])})],1),_c('div',{staticClass:"col-12 red--text"},[_c('h3',[_vm._v("Recuerda que el stand debe estar pago antes del evento.")])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('v-btn',{staticClass:"mr-2 text-none elevation-0",attrs:{"color":"primary"},on:{"click":_vm.saveStep6}},[(!_vm.hasPermission('EDIT_BOOKING'))?_c('span',[_vm._v("Siguiente")]):_vm._e(),(_vm.hasPermission('EDIT_BOOKING'))?_c('span',[_vm._v("Guardar")]):_vm._e()]),_c('v-btn',{staticClass:"mr-2 text-none elevation-0",attrs:{"color":"default"},on:{"click":function($event){return _vm.$emit('nextStep',5)}}},[_vm._v(" Anterior ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }