var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form"},[(_vm.model)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',[_vm._v("Datos de solicitud de stands")])])]),_c('div',{staticClass:"row"},[(_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-3"},[_c('validation-provider',{attrs:{"vid":"companyIdentification","name":_vm.rutLabel,"rules":"required|nit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.hasPermission('EDIT_BOOKING'))?_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":_vm.rutLabel + ' (Sin código de verificación)',"required":""},model:{value:(_vm.model.companyIdentification),callback:function ($$v) {_vm.$set(_vm.model, "companyIdentification", $$v)},expression:"model.companyIdentification"}}):_vm._e()]}}],null,false,1887344124)})],1):_vm._e(),(!_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-3"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.rutLabel)+":")]),_vm._v(" "+_vm._s(_vm.model.companyIdentification)+" ")]):_vm._e(),(_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-3"},[_c('validation-provider',{attrs:{"vid":"companyBusinessName","name":"Razón Social","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Razón social","required":""},model:{value:(_vm.model.companyBusinessName),callback:function ($$v) {_vm.$set(_vm.model, "companyBusinessName", $$v)},expression:"model.companyBusinessName"}})]}}],null,false,119401520)})],1):_vm._e(),(!_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-3"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Razón social:")]),_vm._v(" "+_vm._s(_vm.model.companyBusinessName)+" ")]):_vm._e(),(_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-3"},[_c('validation-provider',{attrs:{"vid":"companyName","name":"Nombre comercial","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Nombre comercial","required":""},model:{value:(_vm.model.companyName),callback:function ($$v) {_vm.$set(_vm.model, "companyName", $$v)},expression:"model.companyName"}})]}}],null,false,1804192209)})],1):_vm._e(),(!_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-3"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Nombre comercial:")]),_vm._v(" "+_vm._s(_vm.model.companyName)+" ")]):_vm._e(),(_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-3"},[_c('validation-provider',{attrs:{"vid":"companyEmail","name":"Correo corporativo","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","outlined":"","dense":"","error-messages":errors,"label":"Correo corporativo","required":""},model:{value:(_vm.model.companyEmail),callback:function ($$v) {_vm.$set(_vm.model, "companyEmail", $$v)},expression:"model.companyEmail"}})]}}],null,false,1052283635)})],1):_vm._e(),(!_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-3"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Correo corporativo:")]),_vm._v(" "+_vm._s(_vm.model.companyEmail)+" ")]):_vm._e()]),_c('div',{staticClass:"row"},[(_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-4"},[_c('validation-provider',{attrs:{"vid":"companyPhone","name":"Teléfono de la empresa","rules":{required: true,phone: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input-vuetify',{staticClass:"flex-wrap",attrs:{"label":"Teléfono de la empresa","error-messages":errors,"mode":"international","inputOptions":{showDialCode:false},"required":"","outlined":"","dense":"","preferred-countries":['CO', 'US']},model:{value:(_vm.model.companyPhone),callback:function ($$v) {_vm.$set(_vm.model, "companyPhone", $$v)},expression:"model.companyPhone"}})]}}],null,false,306420543)})],1):_vm._e(),(!_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-3"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Teléfono de la empresa:")]),_vm._v(" "+_vm._s(_vm.model.companyPhone)+" ")]):_vm._e(),(_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-4"},[_c('validation-provider',{attrs:{"name":"Dirección","vid":"companyAddress","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Dirección","required":""},model:{value:(_vm.model.companyAddress),callback:function ($$v) {_vm.$set(_vm.model, "companyAddress", $$v)},expression:"model.companyAddress"}})]}}],null,false,1190570886)})],1):_vm._e(),(!_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-3"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Dirección:")]),_vm._v(" "+_vm._s(_vm.model.companyAddress)+" ")]):_vm._e(),(_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-4"},[_c('validation-provider',{attrs:{"name":"Página Web","vid":"companyWebSite","rules":"max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Página Web","required":""},model:{value:(_vm.model.companyWebSite),callback:function ($$v) {_vm.$set(_vm.model, "companyWebSite", $$v)},expression:"model.companyWebSite"}})]}}],null,false,2456148741)})],1):_vm._e(),(!_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-3"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Página Web:")]),_vm._v(" "+_vm._s(_vm.model.companyWebSite)+" ")]):_vm._e()]),_c('div',{staticClass:"row"},[(_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-4"},[_c('validation-provider',{attrs:{"vid":"companyCountryId","name":"País","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.countries,"outlined":"","dense":"","item-text":"name","item-value":"id","label":"País"},on:{"change":_vm.onChangeCountry},model:{value:(_vm.model.companyCountryId),callback:function ($$v) {_vm.$set(_vm.model, "companyCountryId", $$v)},expression:"model.companyCountryId"}})]}}],null,false,2476293122)})],1):_vm._e(),(!_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-3"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("País:")]),_vm._v(" "+_vm._s(_vm.model.companyCountryName)+" ")]):_vm._e(),(_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-4"},[_c('validation-provider',{attrs:{"vid":"companyDepartmentId","name":"Departamento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.departments,"outlined":"","dense":"","item-text":"name","item-value":"id","label":"Departamento"},on:{"change":_vm.onChangeDepartment},model:{value:(_vm.model.companyDepartmentId),callback:function ($$v) {_vm.$set(_vm.model, "companyDepartmentId", $$v)},expression:"model.companyDepartmentId"}})]}}],null,false,2526579774)})],1):_vm._e(),(!_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-3"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Departamento:")]),_vm._v(" "+_vm._s(_vm.model.companyDepartmentName)+" ")]):_vm._e(),(_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-4"},[_c('validation-provider',{attrs:{"vid":"companyCityId","name":"Ciudad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.cities,"outlined":"","dense":"","item-text":"name","item-value":"id","label":"Ciudad"},model:{value:(_vm.model.companyCityId),callback:function ($$v) {_vm.$set(_vm.model, "companyCityId", $$v)},expression:"model.companyCityId"}})]}}],null,false,2186034449)})],1):_vm._e(),(!_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-3"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Ciudad:")]),_vm._v(" "+_vm._s(_vm.model.companyCityName)+" ")]):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',[_vm._v("Gerente de la empresa")])])]),_c('div',{staticClass:"row"},[(_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"vid":"CEOName","name":"Nombre","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Nombre","required":""},model:{value:(_vm.model.CEOName),callback:function ($$v) {_vm.$set(_vm.model, "CEOName", $$v)},expression:"model.CEOName"}})]}}],null,false,357436334)})],1):_vm._e(),(!_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-6"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Nombre:")]),_vm._v(" "+_vm._s(_vm.model.CEOName)+" ")]):_vm._e(),(_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"vid":"CEOPositionDetail","name":"Cargo","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Cargo","required":""},model:{value:(_vm.model.CEOPositionDetail),callback:function ($$v) {_vm.$set(_vm.model, "CEOPositionDetail", $$v)},expression:"model.CEOPositionDetail"}})]}}],null,false,2990385664)})],1):_vm._e(),(!_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-6"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Cargo:")]),_vm._v(" "+_vm._s(_vm.model.CEOPositionDetail)+" ")]):_vm._e(),(_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"vid":"CEOEmail","name":"Correo electrónico","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Correo electrónico","required":""},model:{value:(_vm.model.CEOEmail),callback:function ($$v) {_vm.$set(_vm.model, "CEOEmail", $$v)},expression:"model.CEOEmail"}})]}}],null,false,1117539243)})],1):_vm._e(),(!_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-6"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Correo electrónico:")]),_vm._v(" "+_vm._s(_vm.model.CEOEmail)+" ")]):_vm._e(),(_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"flex-grow-1"},[_c('validation-provider',{attrs:{"name":"Teléfono","rules":{required: true,phone:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input-vuetify',{attrs:{"label":"Teléfono","placeholder":"","error-messages":errors,"mode":"international","inputOptions":{showDialCode:false},"required":"","outlined":"","dense":"","preferred-countries":['CO', 'US']},model:{value:(_vm.model.CEOPhone),callback:function ($$v) {_vm.$set(_vm.model, "CEOPhone", $$v)},expression:"model.CEOPhone"}})]}}],null,false,4251209371)})],1),_c('div',{staticClass:"flex-grow-1"},[_c('validation-provider',{attrs:{"name":"Extensión","vid":"CEOPhoneExtension","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Extensión","required":""},model:{value:(_vm.model.CEOPhoneExtension),callback:function ($$v) {_vm.$set(_vm.model, "CEOPhoneExtension", $$v)},expression:"model.CEOPhoneExtension"}})]}}],null,false,3428031344)})],1)])]):_vm._e(),(!_vm.hasPermission('EDIT_BOOKING'))?_c('div',{staticClass:"col-12 col-md-6"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Teléfono:")]),_vm._v(" "+_vm._s(_vm.model.CEOPhone)+" - Ext "+_vm._s(_vm.model.CEOPhoneExtension)+" ")]):_vm._e()]),_c('div',{staticClass:"text-h5 red--text"},[_c('span',{staticClass:"font-bold"},[_vm._v("Nota:")]),_vm._v(" Los "),_c('span',{staticClass:"font-italic"},[_vm._v("Contactos "+_vm._s(_vm.appName))]),_vm._v(" , son las personas que recibirán todas las comunicaciones para la participación en la Feria. Por lo anterior, es importante que tengan la disponibilidad para leer y analizar la información enviada y en caso de ser necesario, responder oportunamente. "),_c('br'),_vm._v("El primer y segundo contacto no pueden tener el mismo correo y deben ser únicos por reserva. (No deben haber sido usados en otra reserva de esta feria) ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',[_vm._v("Primer contacto para "+_vm._s(_vm.appName))])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"name":"Nombre","vid":"firstContactName","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Nombre","required":""},model:{value:(_vm.model.firstContactName),callback:function ($$v) {_vm.$set(_vm.model, "firstContactName", $$v)},expression:"model.firstContactName"}})]}}],null,false,419077885)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"vid":"firstContactEmail","name":"Correo electrónico","rules":"required|email|firstContactEmail:@secondContactEmail|forbiddenUsernames"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"type":"email","outlined":"","dense":"","error-messages":errors,"label":"Correo electrónico","required":""},model:{value:(_vm.model.firstContactEmail),callback:function ($$v) {_vm.$set(_vm.model, "firstContactEmail", $$v)},expression:"model.firstContactEmail"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,false,1150735745)},[_c('span',[_vm._v("Correos no permitidos: abuse, admin, avidandlb, billing, compliance, devnull, ftp, hostmaster, inoc, ispfeedback, ispsupport, list, list-request, maildaemon, noc, noreplyno-reply, null, phish, phishing, popmaster, postmaster, privacy, registrar, root, security, soporte, spam, support, sysadmin, tech, undisclosed-recipients, unsubscribe, usenet, uucp, webmaster, webmasters, www")])])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"name":"Cargo","rules":"required|max:80","vid":"firstContact.occupationDetail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Cargo","required":""},model:{value:(_vm.model.firstContactPositionDetail),callback:function ($$v) {_vm.$set(_vm.model, "firstContactPositionDetail", $$v)},expression:"model.firstContactPositionDetail"}})]}}],null,false,2787903539)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"flex-grow-1"},[_c('validation-provider',{attrs:{"name":"Teléfono","vid":"firstContactPhone","rules":{ phone: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input-vuetify',{attrs:{"label":"Teléfono","mode":"international","error-messages":errors,"required":"","outlined":"","dense":"","preferred-countries":['CO', 'US']},model:{value:(_vm.model.firstContactPhone),callback:function ($$v) {_vm.$set(_vm.model, "firstContactPhone", $$v)},expression:"model.firstContactPhone"}})]}}],null,false,2670155426)})],1),_c('div',{staticClass:"flex-grow-1"},[_c('validation-provider',{attrs:{"name":"Extensión","vid":"firstContactPhoneExtension","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Extensión","required":""},model:{value:(_vm.model.firstContactPhoneExtension),callback:function ($$v) {_vm.$set(_vm.model, "firstContactPhoneExtension", $$v)},expression:"model.firstContactPhoneExtension"}})]}}],null,false,58279043)})],1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"vid":"firstContactMobilePhone","name":"Teléfono","rules":{ required: true, phone: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input-vuetify',{staticClass:"flex-wrap",attrs:{"label":"Celular","mode":"international","inputOptions":{ showDialCode: false },"error-messages":errors,"required":"","outlined":"","dense":"","preferred-countries":['CO', 'US']},model:{value:(_vm.model.firstContactMobilePhone),callback:function ($$v) {_vm.$set(_vm.model, "firstContactMobilePhone", $$v)},expression:"model.firstContactMobilePhone"}})]}}],null,false,3342540143)})],1)])]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',[_vm._v("Segundo contacto para "+_vm._s(_vm.appName))])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"name":"Nombre","vid":"secondContactName","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Nombre","required":""},model:{value:(_vm.model.secondContactName),callback:function ($$v) {_vm.$set(_vm.model, "secondContactName", $$v)},expression:"model.secondContactName"}})]}}],null,false,3282004759)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"Correo electrónico","vid":"secondContactEmail","rules":"required|email|secondContactEmail:@firstContactEmail|forbiddenUsernames"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"type":"email","outlined":"","dense":"","error-messages":errors,"label":"Correo electrónico","required":""},model:{value:(_vm.model.secondContactEmail),callback:function ($$v) {_vm.$set(_vm.model, "secondContactEmail", $$v)},expression:"model.secondContactEmail"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,false,3866793057)},[_c('span',[_vm._v("Correos no permitidos: abuse, admin, avidandlb, billing, compliance, devnull, ftp, hostmaster, inoc, ispfeedback, ispsupport, list, list-request, maildaemon, noc, noreplyno-reply, null, phish, phishing, popmaster, postmaster, privacy, registrar, root, security, soporte, spam, support, sysadmin, tech, undisclosed-recipients, unsubscribe, usenet, uucp, webmaster, webmasters, www")])])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"name":"Cargo","vid":"secondContactPositionDetail","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Cargo","required":""},model:{value:(_vm.model.secondContactPositionDetail),callback:function ($$v) {_vm.$set(_vm.model, "secondContactPositionDetail", $$v)},expression:"model.secondContactPositionDetail"}})]}}],null,false,2945138073)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"flex-grow-1"},[_c('validation-provider',{attrs:{"name":"Teléfono","vid":"secondContactPhone","rules":{ phone: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input-vuetify',{attrs:{"label":"Teléfono","mode":"international","error-messages":errors,"required":"","outlined":"","dense":"","preferred-countries":['CO', 'US']},model:{value:(_vm.model.secondContactPhone),callback:function ($$v) {_vm.$set(_vm.model, "secondContactPhone", $$v)},expression:"model.secondContactPhone"}})]}}],null,false,2287609032)})],1),_c('div',{staticClass:"flex-grow-1"},[_c('validation-provider',{attrs:{"name":"Extensión","vid":"secondContact.phoneExtension","rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Extensión","required":""},model:{value:(_vm.model.secondContactPhoneExtension),callback:function ($$v) {_vm.$set(_vm.model, "secondContactPhoneExtension", $$v)},expression:"model.secondContactPhoneExtension"}})]}}],null,false,3072390889)})],1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"name":"Teléfono","vid":"secondContactMobilePhone","rules":{ required: true, phone: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input-vuetify',{attrs:{"label":"Celular","mode":"international","inputOptions":{ showDialCode: false },"error-messages":errors,"required":"","outlined":"","dense":"","preferred-countries":['CO', 'US']},model:{value:(_vm.model.secondContactMobilePhone),callback:function ($$v) {_vm.$set(_vm.model, "secondContactMobilePhone", $$v)},expression:"model.secondContactMobilePhone"}})]}}],null,false,1385514923)})],1)])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('v-btn',{staticClass:"mr-2 text-none elevation-0",attrs:{"color":"primary"},on:{"click":_vm.saveStep1}},[(!_vm.hasPermission('EDIT_BOOKING'))?_c('span',[_vm._v("Siguiente")]):_vm._e(),(_vm.hasPermission('EDIT_BOOKING'))?_c('span',[_vm._v("Guardar")]):_vm._e()]),(_vm.hasPermission('EDIT_BOOKING'))?_c('v-btn',{staticClass:"mr-2 text-none elevation-0",attrs:{"color":"default"},on:{"click":function($event){return _vm.$emit('nextStep', 0)}}},[_vm._v(" Anterior ")]):_vm._e()],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }