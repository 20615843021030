var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form"},[_c('div',{staticClass:"px-7 row"},[_c('div',{staticClass:"col-12"},[_c('file-chooser',{ref:"fileChooser",attrs:{"options":{
        width: '400px',
        height: '200px',
        description: 'Haz clic aquí para cargar el logo de la empresa',
        regex: /\.(jpg|jpeg|png|gif)$/,
        error: 'Solo se permiten imagenes',
        fileSize: 2
      }},on:{"input":_vm.onLoadCompanyLogo}}),_c('v-tooltip',{staticClass:"col-auto",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":_vm.onDeleteImage}},'v-icon',attrs,false),on),[_vm._v(" mdi-trash-can-outline ")])]}}])},[_c('span',[_vm._v("Eliminar Imagen")])]),(_vm.roleId !== 3 && _vm.roleId !== 5 && _vm.logoUrl)?_c('a',{attrs:{"href":_vm.logoUrl,"target":"_blank"}},[_vm._v("Descargar")]):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"vid":"constitutionYear","name":"Año de constitución","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","outlined":"","dense":"","error-messages":errors,"label":"Año de constitución*","required":""},model:{value:(_vm.model.constitutionYear),callback:function ($$v) {_vm.$set(_vm.model, "constitutionYear", $$v)},expression:"model.constitutionYear"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"vid":"companySizeId","name":"Tamaño","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.companySizes,"item-text":"name","item-value":"id","outlined":"","dense":"","label":"Tamaño*"},model:{value:(_vm.model.companySizeId),callback:function ($$v) {_vm.$set(_vm.model, "companySizeId", $$v)},expression:"model.companySizeId"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"vid":"employeesAmount","name":"Número de empleados","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","outlined":"","dense":"","error-messages":errors,"label":"Número de empleados*","required":""},model:{value:(_vm.model.employeesAmount),callback:function ($$v) {_vm.$set(_vm.model, "employeesAmount", $$v)},expression:"model.employeesAmount"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('validation-provider',{attrs:{"vid":"customersAmount","name":"Número de clientes","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","outlined":"","dense":"","error-messages":errors,"label":"Número de clientes*","required":""},model:{value:(_vm.model.customersAmount),callback:function ($$v) {_vm.$set(_vm.model, "customersAmount", $$v)},expression:"model.customersAmount"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('v-btn',{staticClass:"mr-2 text-none elevation-0",attrs:{"color":"primary"},on:{"click":_vm.saveStep3}},[(!_vm.hasPermission('EDIT_BOOKING'))?_c('span',[_vm._v("Siguiente")]):_vm._e(),(_vm.hasPermission('EDIT_BOOKING'))?_c('span',[_vm._v("Guardar")]):_vm._e()]),_c('v-btn',{staticClass:"mr-2 text-none elevation-0",attrs:{"color":"default"},on:{"click":function($event){return _vm.$emit('nextStep',2)}}},[_vm._v(" Anterior ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }